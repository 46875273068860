import { useState, useEffect } from "react";
import logo from './logo.svg'
import styles from './App.module.css'
import App2 from './abstract/App';
import { AnimationProvider } from './blip/AnimationContext';

import FMAAS from './config/sync4a/fmaas/FMAAS';
import FMAAS12L from './config/sync412l/fmaas/FMAAS';
import AAAS from './config/sync4a/aaas/AAAS';
import AAAS12L from './config/sync412l/aaas/AAAS';
import FMAEA from './config/sync4a/fmaea/FMAEA';
import FMAEA12L from './config/sync412l/fmaea/FMAEA';
import AAEA from './config/sync4a/aaea/AAEA';
import AAEA12L from './config/sync412l/aaea/AAEA';

import upbeat from './audio/upbeat.mp3';
import summer from './audio/summer.mp3';
import rock from './audio/rock.mp3';
const songs = [
  {
    song: upbeat,
    artist: "Artist 1",
    title: "Upbeat",
    albumCover: null
  },
  {
    song: summer,
    artist: "Artist 2",
    title: "Summer",
    albumCover: null
  },
  {
    song: rock,
    artist: "Artist 3",
    title: "Rock",
    albumCover: null
  }
]

function App() {
  const [isPlaying, setPlaying] = useState(false);
  const [audio, setAudio] = useState(null);
  const [songIndex, setSongIndex] = useState(0);
  const [app, setApp] = useState(null);

  useEffect(() => {
    loadSong(0, false)
  }, [])

  useEffect(() => {
    if (app === null) {
      if (audio !== null) {
        stopPlayback();
      }
    }
  }, [app])

  const loadSong = (index, autoplay) => {
    const audioElement = new Audio(songs[index].song);
    setSongIndex(index);
    audioElement.addEventListener("loadeddata", () => {
      let duration = audioElement.duration;
      console.log(duration);

      setAudio(audioElement);
      if (autoplay) {
        audioElement.play();
      }
    });

    return songs[index];
  }  

  const startPlayback = () => {
    audio.play();
    setPlaying(true);    
  }

  const stopPlayback = () => {
    audio.pause();
    audio.currentTime = 0;
    setPlaying(false);
  }

  const togglePlayback = () => {
    if (isPlaying) {
      audio.pause();
      setPlaying(false);
    } else {
      audio.play();
      setPlaying(true);
    }
  }

  const nextSong = () => {
    audio.pause();
    if (songIndex + 1 === songs.length) {
      return loadSong(0, isPlaying);
    } else {
      return loadSong(songIndex + 1, isPlaying);
    }
  }

  const prevSong = () => {
    audio.pause();
    if (songIndex - 1 < 0) {
      return loadSong(songs.length - 1, isPlaying);
    } else {
      return loadSong(songIndex - 1, isPlaying);
    }
  }

  const getPlaying = () => {
    return isPlaying;
  }

  const getSong = () => {
    return songs[songIndex];
  }

  const reset = () => {
    setApp(null);
  }

  const apps = [
    [FMAAS, FMAAS12L],
    [AAAS, AAAS12L],
    [FMAEA, FMAEA12L],
    [AAEA, AAEA12L]
  ];

  const playbackControls = {
    togglePlayback,
    startPlayback,
    stopPlayback,
    nextSong,
    prevSong,
    getPlaying,
    getSong
  }

  return (
    <div className={styles.App}>
      {app === null && 
      <table className={styles.AppTable}>
        <tbody>
          <tr className={styles.AppTableRow}>
              <th className={styles.AppTableData}>SYNC 4A</th>
              <th className={styles.AppTableData}>SYNC 4 12L</th>
          </tr>
          {apps.map((t, i) => (
            <tr className={styles.AppTableRow} key={i}>
              <td className={styles.AppTableData} onClick={() => setApp(t[0])}>{t[0].Title}</td>
              <td className={styles.AppTableData} onClick={() => setApp(t[1])}>{t[1].Title}</td>
            </tr>
          ))}
        </tbody>
      </table>
      }
      {app !== null && 
      <AnimationProvider>
        <App2 config={app} reset={reset} audio={playbackControls}/>
      </AnimationProvider>
      } 
    </div>
  );
}

export default App;
