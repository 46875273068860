import tray from './img/Sync4_12L_AppsTray_EntertainmentApps 1.png';
import tray2 from './img/Sync4_12L_AppsTray_FordMusic.png';
import discover from './img/Sync4_12L_Discover_FordMusic.png';
import player from './img/Sync4_12L_EntertainmentApp_FordMusic.png';

import BlipButton from '../../../blip/BlipButton';
import styles from './FMAEA.module.css';

function Tabs(props) {
  return (
    <div>
      <BlipButton className={styles.FMAEAAudioButton} onClick={() => props.nextIndex(0)} />
      <BlipButton className={styles.FMAEAAppsButton} onClick={() => props.nextIndex(1)} />
    </div>
  );
}

function Default(props) {
  return (
    <div>
      <img className={styles.AppChrome} src={props.bg}/>
    </div>
  );
}

function AppTray(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className={styles.HomeButton} onClick={() => props.reset && props.reset()} />
    </div>
  )
}

function EntertainmentAppsTray(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className={styles.FMAEAFordAppIcon} onClick={() => props.next && props.next(props.nextPage)} />
    </div>
  )
}

function Discovery(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className={styles.FMAEARecentlyPlayed} onClick={() => props.next && props.next(props.nextPage)} />
      <BlipButton className={styles.HomeButton} onClick={() => props.home && props.home()} />
    </div>
  )
}

function MediaPlayer(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className={styles.FMAEAMediaPlayerBackButton} onClick={() => props.prev && props.prev()} />
    </div>
  )
}

const FMAEAConfig = {
  "Title": "Ford Music as an Entertainment App",
  "AppTrayContentTabs": {
    "component": Tabs,
    "tabs": [
      {
        "title": "Amazon Music",
        "index": 0
      },
      {
        "title": "Pandora",
        "index": 1
      },
    ]
  },
  "AppTrayContentPages": [
    {
      "title": "",
      "bg": tray,
      "component": AppTray,
      "next": 0
    },
    {
      "title": "",
      "bg": tray2,
      "component": EntertainmentAppsTray,
      "next": 0
    }
  ],
  "DiscoveryContentPages": [
    {
      "title": "Ford Music",
      "bg": discover,
      "component": Discovery,
      "next": 0
    }
  ],
  "MusicPlayerContentPages": [
    {
      "bg": player,
      "component": MediaPlayer,
      "musicPlayer": {
        "bg": "bg3.png",
        "playbackComponent": "<FordMusicPlayerPlayback />",
        "topBarComponent": "<FordMusicPlayerTopBar />"
      }
    }
  ]
}

export default FMAEAConfig;