
function AppTrayPage(props) {
  const { config, index } = props;
  const pages = config.AppTrayContentPages;

  const PageComponent = pages[index].component;
  const TabComponent = config.AppTrayContentTabs ? config.AppTrayContentTabs.component : null;
  return (
    <div>
      {TabComponent && <TabComponent config={config} nextIndex={props.nextIndex}/>}
      <PageComponent nextPage={pages[index].next} bg={pages[index].bg} {...props}/>
    </div>
  );

}

export default AppTrayPage;