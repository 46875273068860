import tray from './img/Ref.png';
import tray2 from './img/Sync4A_AppTray_FordMusic.png';
import discover from './img/Sync4A_EntertainmentApp_FordMusic_Discovery.png';
import player from './img/Sync4A_EntertainmentApp_FordMusic.png';

import BlipButton from '../../../blip/BlipButton';
import './FMAEA.css';

function Tabs(props) {
  return (
    <div className="FMAEATabs">
      <table className="FMAEATabsTable">
        <tbody>
          <tr>
            <td><BlipButton className="FMAEATab" onClick={() => props.nextIndex(0)}/></td>
            <td><BlipButton className="FMAEATab" onClick={() => props.nextIndex(1)}/></td>
          </tr>
        </tbody>      
      </table>
    </div>
  );
}

function Default(props) {
  return (
    <div>
      <img className="Chrome" src={props.bg}/>
      <BlipButton className="HomeButton" onClick={() => props.reset && props.reset()} />
    </div>
  );
}

function EntertainmentAppsTray(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className="FMAEAFordAppIcon" onClick={() => props.next && props.next(props.nextPage)} />
    </div>
  )
}

function Discovery(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className="FMAEARecentlyPlayed" onClick={() => props.next && props.next(props.nextPage)} />
      <BlipButton className="HomeButton" onClick={() => props.home && props.home()} />
    </div>
  )
}

function MediaPlayer(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className="FMAEAMediaPlayerBackButton" onClick={() => props.prev && props.prev()} />
      <BlipButton className="HomeButton" onClick={() => props.home && props.home()} />
    </div>
  )
}

const FMAEAConfig = {
  "Title": "Ford Music as an Entertainment App",
  "AppTrayContentTabs": {
    "component": Tabs,
    "tabs": [
      {
        "title": "Amazon Music",
        "index": 0
      },
      {
        "title": "Pandora",
        "index": 1
      },
    ]
  },
  "AppTrayContentPages": [
    {
      "title": "",
      "bg": tray,
      "component": Default,
      "next": 0
    },
    {
      "title": "",
      "bg": tray2,
      "component": EntertainmentAppsTray,
      "next": 0
    }
  ],
  "DiscoveryContentPages": [
    {
      "title": "Ford Music",
      "bg": discover,
      "component": Discovery,
      "next": 0
    }
  ],
  "MusicPlayerContentPages": [
    {
      "bg": player,
      "component": MediaPlayer,
      "musicPlayer": {
        "bg": "bg3.png",
        "playbackComponent": "<FordMusicPlayerPlayback />",
        "topBarComponent": "<FordMusicPlayerTopBar />"
      }
    }
  ]
}

export default FMAEAConfig;