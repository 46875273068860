import { useState } from 'react';
import AppTrayPage from './AppTrayPage'
import DiscoveryPage from './DiscoveryPage'
import MusicPlayerPage from './MusicPlayerPage'
import { useAnimation } from '../blip/AnimationContext'
import Blip from '../blip/Blip'

import './variables.css';
import styles from './App.module.css';

function App(props) {
  const { config } = props;//AAEA12L;
  const [state, setState] = useState(0);
  const [nextIndex, setNextIndex] = useState(0);

  const blip = useAnimation();

  const next = (index) => {
    const s = state;
    setState(s + 1);
    setNextIndex(index ? index : 0);
  } 

  const nextIdx = (index) => {
    setNextIndex(index);
  }

  const prev = (index) => {
    const s = state;
    setState(s - 1);
    setNextIndex(index ? index : nextIndex);
  }

  const home = () => {
    setState(0);
    setNextIndex(0);
  }

  return (
    <div className={styles.Frame} onClick={blip.triggerAnimation}>
      {state === 0 && <AppTrayPage config={config} index={nextIndex} next={next} prev={prev} home={home} nextIndex={nextIdx} {...props}/>}
      {state === 1 && <DiscoveryPage config={config} index={nextIndex} next={next} prev={prev} home={home} nextIndex={nextIdx} {...props}/>}
      {state === 2 && <MusicPlayerPage config={config} index={nextIndex} next={next} prev={prev} home={home} nextIndex={nextIdx} {...props}/>}
    </div>
  );
}

export default App;