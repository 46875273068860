import tray from './img/Sync4_12L_Sources_AmazonPandoraSpotify.png';
import amazon from './img/Sync4_12L_Discover_AmazonMusic_AudioSource.png';
import pandora from './img/Sync4_12L_Discover_Pandora_AudioSource.png';
import spotify from './img/Sync4_12L_Discover_Spotify_AudioSource.png';

import amazonPlayer from './img/Sync4_12L_AudioSource_AmazonMusic.png';
import pandoraPlayer from './img/Sync4_12L_AudioSource_Pandora.png';
import spotifyPlayer from './img/Sync4_12L_AudioSource_Spotify.png';

import BlipButton from '../../../blip/BlipButton';
import styles from './AAAS.module.css';

function Default(props) {
  return (
    <div>
      <img className={styles.AppChrome} src={props.bg} />
    </div>
  );
}

function AppsTray(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className={styles.AAASAmazonIcon} onClick={() => props.next && props.next(0)} />
      <BlipButton className={styles.AAASPandoraIcon} onClick={() => props.next && props.next(1)} />
      <BlipButton className={styles.AAASSpotifyIcon} onClick={() => props.next && props.next(2)} />
      <BlipButton className={styles.HomeButton} onClick={() => props.reset && props.reset()} />
    </div>
  )
}

function Discovery(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className={styles.AAASRecentlyPlayed} onClick={() => props.next && props.next(props.nextPage)} />
      <BlipButton className={styles.HomeButton} onClick={() => props.home && props.home()} />
    </div>
  )
}

function MediaPlayer(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className={styles.AAASMediaPlayerBackButton} onClick={() => props.prev && props.prev()} />
      <BlipButton className={styles.AAASMediaPlayerSourcesButton} onClick={() => props.home && props.home()} />
    </div>
  )
}

const AAASConfig = {
  "Title": "Amazon Music, Pandora, and Spotify as Audio Sources",
  "AppTrayContentPages": [
    {
      "title": "",
      "bg": tray,
      "component": AppsTray,
      "next": 0
    }
  ],
  "DiscoveryContentPages": [
    {
      "title": "Amazon Music",
      "bg": amazon,
      "component": Discovery,
      "next": 0
    },
    {
      "title": "Pandora",
      "bg": pandora,
      "component": Discovery,
      "next": 1
    },
    {
      "title": "Spotify",
      "bg": spotify,
      "component": Discovery,
      "next": 2
    }
  ],
  "MusicPlayerContentPages": [
    {
      "bg": amazonPlayer,
      "component": MediaPlayer,
      "musicPlayer": {
        "bg": "bg3.png",
        "playbackComponent": "<FordMusicPlayerPlayback />",
        "topBarComponent": "<FordMusicPlayerTopBar />"
      }
    },
    {
      "bg": pandoraPlayer,
      "component": MediaPlayer,
      "musicPlayer": {
        "bg": "bg3.png",
        "playbackComponent": "<FordMusicPlayerPlayback />",
        "topBarComponent": "<FordMusicPlayerTopBar />"
      }
    },
    {
      "bg": spotifyPlayer,
      "component": MediaPlayer,
      "musicPlayer": {
        "bg": "bg3.png",
        "playbackComponent": "<FordMusicPlayerPlayback />",
        "topBarComponent": "<FordMusicPlayerTopBar />"
      }
    }
  ]
}

/*{
  "AppTrayContentPages": [
    {
      "title": "",
      "bg": tray,
      "component": Default,
      "next": 0,
    }
  ],
  "DiscoveryContentPages": [
    {
      "title": "Ford Music",
      "bg": discover,
      "component": Default
    }
  ],
  "MusicPlayerContenPages": [
    {
      "bg": player,
      "musicPlayer": {
        "bg": "bg3.png",
        "playbackComponent": "<FordMusicPlayerPlayback />",
        "topBarComponent": "<FordMusicPlayerTopBar />"
      }
    }
  ]
}*/

export default AAASConfig;