import React from 'react';
import Blip from './Blip'

const BlipButton = (props) => {

  const onC = (e) => {
    e.stopPropagation();

    props.onClick();
  }

  return (
    <div className={props.className} onClick={onC}>
      <Blip />
    </div>
  );
};

export default BlipButton;