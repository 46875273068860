import tray from './img/Ref.png';
import amazon from './img/Sync4A_AudioSource_AmazonMusic_Discovery.png';
import pandora from './img/Sync4A_AudioSource_Pandora_Discovery.png';
import spotify from './img/Sync4A_AudioSource_Spotify_Discovery.png';

import amazonPlayer from './img/Sync4A_AudioSource_AmazonMusic.png';
import pandoraPlayer from './img/Sync4A_AudioSource_Pandora.png';
import spotifyPlayer from './img/Sync4A_AudioSource_Spotify.png';

import BlipButton from '../../../blip/BlipButton';
import styles from './AAAS.module.css';

function Tabs(props) {
  return (
    <div className={styles.AAASTabs}>
      <table className={styles.AAASTabsTable}>
        <tbody>
          <tr>
            <td><BlipButton className={styles.AAASTab} onClick={() => props.nextIndex(0)}/></td>
            <td><BlipButton className={styles.AAASTab} onClick={() => props.nextIndex(1)}/></td>
            <td><BlipButton className={styles.AAASTab} onClick={() => props.nextIndex(2)}/></td>
          </tr>
        </tbody>      
      </table>
    </div>
  );
}

function Default(props) {
  return (
    <div>
      <img className={styles.Chrome} src={props.bg} />
      <BlipButton className="HomeButton" onClick={() => props.reset && props.reset()} />
    </div>
  );
}

function AppsTray(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className={styles.AAASRadioIcon} onClick={() => props.next && props.next(props.nextPage)} />
    </div>
  )
}

function Discovery(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className={styles.AAASRecentlyPlayed} onClick={() => props.next && props.next(props.nextPage)} />
      <BlipButton className={styles.HomeButton} onClick={() => props.home && props.home()} />
    </div>
  )
}

function MediaPlayer(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className={styles.AAASMediaPlayerBackButton} onClick={() => props.prev && props.prev()} />
      <BlipButton className={styles.HomeButton} onClick={() => props.home && props.home()} />
    </div>
  )
}

const AAASConfig = {
  "Title": "Amazon Music, Pandora, and Spotify as Audio Sources",
  "AppTrayContentPages": [
    {
      "title": "",
      "bg": tray,
      "component": AppsTray,
      "next": 0
    }
  ],
  "DiscoveryContentTabs": {
    "component": Tabs,
    "tabs": [
      {
        "title": "Amazon Music",
        "index": 0
      },
      {
        "title": "Pandora",
        "index": 1
      },
      {
        "title": "Spotify",
        "index": 2
      }
    ]
  },
  "DiscoveryContentPages": [
    {
      "title": "Amazon Music",
      "bg": amazon,
      "component": Discovery,
      "next": 0
    },
    {
      "title": "Pandora",
      "bg": pandora,
      "component": Discovery,
      "next": 1
    },
    {
      "title": "Spotify",
      "bg": spotify,
      "component": Discovery,
      "next": 2
    }
  ],
  "MusicPlayerContentTabs": {
    "component": Tabs,
    "tabs": [
      {
        "title": "Amazon Music",
        "index": 0
      },
      {
        "title": "Pandora",
        "index": 1
      },
      {
        "title": "Spotify",
        "index": 2
      }
    ]
  },
  "MusicPlayerContentPages": [
    {
      "bg": amazonPlayer,
      "component": MediaPlayer,
      "musicPlayer": {
        "bg": "bg3.png",
        "playbackComponent": "<FordMusicPlayerPlayback />",
        "topBarComponent": "<FordMusicPlayerTopBar />"
      }
    },
    {
      "bg": pandoraPlayer,
      "component": MediaPlayer,
      "musicPlayer": {
        "bg": "bg3.png",
        "playbackComponent": "<FordMusicPlayerPlayback />",
        "topBarComponent": "<FordMusicPlayerTopBar />"
      }
    },
    {
      "bg": spotifyPlayer,
      "component": MediaPlayer,
      "musicPlayer": {
        "bg": "bg3.png",
        "playbackComponent": "<FordMusicPlayerPlayback />",
        "topBarComponent": "<FordMusicPlayerTopBar />"
      }
    }
  ]
}

/*{
  "AppTrayContentPages": [
    {
      "title": "",
      "bg": tray,
      "component": Default,
      "next": 0,
    }
  ],
  "DiscoveryContentPages": [
    {
      "title": "Ford Music",
      "bg": discover,
      "component": Default
    }
  ],
  "MusicPlayerContenPages": [
    {
      "bg": player,
      "musicPlayer": {
        "bg": "bg3.png",
        "playbackComponent": "<FordMusicPlayerPlayback />",
        "topBarComponent": "<FordMusicPlayerTopBar />"
      }
    }
  ]
}*/

export default AAASConfig;