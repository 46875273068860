import React from 'react';
import { useAnimation } from './AnimationContext'
import './Blip.css'; // Make sure to create and import CSS for animations

const Blip = (props) => {
  const blip = useAnimation();

  return (
    <div className={`blip ${blip.animate ? 'animate' : ''}`}>
    </div>
  );
};

export default Blip;