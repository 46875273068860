import tray from './img/Ref.png';
import tray2 from './img/Sync4A_AppTray_AmazonMusicPandoraSpotify.png'
import amazon from './img/Sync4A_EntertainmentApp_AmazonMusic_Discovery.png';
import pandora from './img/Sync4A_EntertainmentApp_Pandora_Discovery.png';
import spotify from './img/Sync4A_EntertainmentApp_Spotify_Discovery.png';

import amazonPlayer from './img/Sync4A_EntertainmentApp_AmazonMusic.png';
import pandoraPlayer from './img/Sync4A_EntertainmentApp_Pandora.png';
import spotifyPlayer from './img/Sync4A_EntertainmentApp_Spotify.png';

import BlipButton from '../../../blip/BlipButton';
import './AAEA.css';

function Tabs(props) {
  return (
    <div className="AAEATabs">
      <table className="AAEATabsTable">
        <tbody>
          <tr>
            <td><BlipButton className="AAEATab" onClick={() => props.nextIndex(0)}/></td>
            <td><BlipButton className="AAEATab" onClick={() => props.nextIndex(1)}/></td>
          </tr>
        </tbody>      
      </table>
    </div>
  );
}

function Default(props) {
  return (
    <div>
      <img className="Chrome" src={props.bg} />
      <BlipButton className="HomeButton" onClick={() => props.reset && props.reset()} />
    </div>
  );
}

function AppsTray(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className="AAEAAmazonIcon" onClick={() => props.next && props.next(0)} />
      <BlipButton className="AAEAPandoraIcon" onClick={() => props.next && props.next(1)} />
      <BlipButton className="AAEASpotifyIcon" onClick={() => props.next && props.next(2)} />
    </div>
  )
}

function Discovery(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className="AAEARecentlyPlayed" onClick={() => props.next && props.next(props.nextPage)} />
      <BlipButton className="AAEADiscoveryBackButton" onClick={() => props.prev && props.prev(1)} />
      <BlipButton className="HomeButton" onClick={() => props.home && props.home()} />
    </div>
  )
}

function MediaPlayer(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className="AAEAMediaPlayerBackButton" onClick={() => props.prev && props.prev()} />
      <BlipButton className="HomeButton" onClick={() => props.home && props.home()} />
    </div>
  )
}

const AAEAConfig = {
  "Title": "Amazon Music, Pandora, and Spotify as Entertainment Apps",
  "AppTrayContentTabs": {
    "component": Tabs,
    "tabs": [
      {
        "title": "Amazon Music",
        "index": 0
      },
      {
        "title": "Pandora",
        "index": 1
      },
    ]
  },
  "AppTrayContentPages": [
    {
      "title": "",
      "bg": tray,
      "component": Default,
      "next": 0
    },
    {
      "title": "",
      "bg": tray2,
      "component": AppsTray,
      "next": 0
    }
  ],
  "DiscoveryContentPages": [
    {
      "title": "Amazon Music",
      "bg": amazon,
      "component": Discovery,
      "next": 0
    },
    {
      "title": "Pandora",
      "bg": pandora,
      "component": Discovery,
      "next": 1
    },
    {
      "title": "Spotify",
      "bg": spotify,
      "component": Discovery,
      "next": 2
    }
  ],
  "MusicPlayerContentPages": [
    {
      "bg": amazonPlayer,
      "component": MediaPlayer,
      "musicPlayer": {
        "bg": "bg3.png",
        "playbackComponent": "<FordMusicPlayerPlayback />",
        "topBarComponent": "<FordMusicPlayerTopBar />"
      }
    },
    {
      "bg": pandoraPlayer,
      "component": MediaPlayer,
      "musicPlayer": {
        "bg": "bg3.png",
        "playbackComponent": "<FordMusicPlayerPlayback />",
        "topBarComponent": "<FordMusicPlayerTopBar />"
      }
    },
    {
      "bg": spotifyPlayer,
      "component": MediaPlayer,
      "musicPlayer": {
        "bg": "bg3.png",
        "playbackComponent": "<FordMusicPlayerPlayback />",
        "topBarComponent": "<FordMusicPlayerTopBar />"
      }
    }
  ]
}

export default AAEAConfig;