
function DiscoveryPage(props) {
  const { config, index } = props;
  const pages = config.DiscoveryContentPages;
  
  const PageComponent = pages[index].component;
  const TabComponent = config.DiscoveryContentTabs ? config.DiscoveryContentTabs.component : null;

  return (
    <div>
      {TabComponent && <TabComponent {...props}/>}
      <PageComponent bg={pages[index].bg} nextPage={pages[index].next} {...props}/>
    </div>
  );

}

export default DiscoveryPage;