import { useState, useEffect } from "react";

function MusicPlayerPage(props) {
  const { config, index } = props;
  const pages = config.MusicPlayerContentPages;
  
  const TabComponent = config.MusicPlayerContentTabs ? config.MusicPlayerContentTabs.component : null;
  const PageComponent = pages[index].component;

  useEffect(() => {
    props.audio.startPlayback();
    console.log('open')
    return () => {
      //props.audio.stopPlayback();
      console.log('close')
    }
    }, [])

  return (
    <div>
      {TabComponent && <TabComponent {...props}/>}
      <PageComponent bg={pages[index].bg} {...props} />
    </div>
  );

}

export default MusicPlayerPage;