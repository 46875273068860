import tray from './img/Sync4_12L_AppsTray_EntertainmentApps 2.png';
import tray2 from './img/Sync4_12L_AppsTray_AmazonPandoraSpotify.png'
import amazon from './img/Sync4_12L_Discover_AmazonMusic.png';
import pandora from './img/Sync4_12L_Discover_Pandora.png';
import spotify from './img/Sync4_12L_Discover_Spotify.png';

import amazonPlayer from './img/Sync4_12L_EntertainmentApp_AmazonMusic.png';
import pandoraPlayer from './img/Sync4_12L_EntertainmentApp_Pandora.png';
import spotifyPlayer from './img/Sync4_12L_EntertainmentApp_Spotify.png';

import BlipButton from '../../../blip/BlipButton';
import styles from './AAEA.module.css';

function Tabs(props) {
  return (
    <div>
      <BlipButton className={styles.AAEAAudioButton} onClick={() => props.nextIndex(0)} />
      <BlipButton className={styles.AAEAAppsButton} onClick={() => props.nextIndex(1)} />
    </div>
  );
}

function Default(props) {
  return (
    <div>
      <img className={styles.AppChrome} src={props.bg} />
    </div>
  );
}

function AppTray(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className={styles.HomeButton} onClick={() => props.reset && props.reset()} />
    </div>
  )
}

function AppsTray(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className={styles.AAEAAmazonIcon} onClick={() => props.next && props.next(0)} />
      <BlipButton className={styles.AAEAPandoraIcon} onClick={() => props.next && props.next(1)} />
      <BlipButton className={styles.AAEASpotifyIcon} onClick={() => props.next && props.next(2)} />
    </div>
  )
}

function Discovery(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className={styles.AAEARecentlyPlayed} onClick={() => props.next && props.next(props.nextPage)} />
      <BlipButton className={styles.AAEADiscoveryBackButton} onClick={() => props.prev && props.prev(1)} />
      <BlipButton className={styles.HomeButton} onClick={() => props.home && props.home()} />
    </div>
  )
}

function MediaPlayer(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className={styles.AAEAMediaPlayerBackButton} onClick={() => props.prev && props.prev()} />
    </div>
  )
}

const AAEAConfig = {
  "Title": "Amazon Music, Pandora, and Spotify as Entertainment Apps",
  "AppTrayContentTabs": {
    "component": Tabs,
    "tabs": [
      {
        "title": "Amazon Music",
        "index": 0
      },
      {
        "title": "Pandora",
        "index": 1
      },
    ]
  },
  "AppTrayContentPages": [
    {
      "title": "",
      "bg": tray,
      "component": AppTray,
      "next": 0
    },
    {
      "title": "",
      "bg": tray2,
      "component": AppsTray,
      "next": 0
    }
  ],
  "DiscoveryContentPages": [
    {
      "title": "Amazon Music",
      "bg": amazon,
      "component": Discovery,
      "next": 0
    },
    {
      "title": "Pandora",
      "bg": pandora,
      "component": Discovery,
      "next": 1
    },
    {
      "title": "Spotify",
      "bg": spotify,
      "component": Discovery,
      "next": 2
    }
  ],
  "MusicPlayerContentPages": [
    {
      "bg": amazonPlayer,
      "component": MediaPlayer,
      "musicPlayer": {
        "bg": "bg3.png",
        "playbackComponent": "<FordMusicPlayerPlayback />",
        "topBarComponent": "<FordMusicPlayerTopBar />"
      }
    },
    {
      "bg": pandoraPlayer,
      "component": MediaPlayer,
      "musicPlayer": {
        "bg": "bg3.png",
        "playbackComponent": "<FordMusicPlayerPlayback />",
        "topBarComponent": "<FordMusicPlayerTopBar />"
      }
    },
    {
      "bg": spotifyPlayer,
      "component": MediaPlayer,
      "musicPlayer": {
        "bg": "bg3.png",
        "playbackComponent": "<FordMusicPlayerPlayback />",
        "topBarComponent": "<FordMusicPlayerTopBar />"
      }
    }
  ]
}

export default AAEAConfig;