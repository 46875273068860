import tray from './img/Sync4_12L_Sources_FordMusic.png';
import discover from './img/Sync4_12L_Discover_FordMusic_AudioSource.png';
import player from './img/Sync4_12L_AudioSource_FordMusic.png';

import BlipButton from '../../../blip/BlipButton';
import styles from './FMAAS.module.css'

function Default(props) {
  return (
    <div>
      <img className={styles.AppChrome} src={props.bg}/>
    </div>
  );
}

function AppsTray(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className={styles.FMAASRadioIcon} onClick={() => props.next && props.next(props.nextPage)} />
      <BlipButton className={styles.HomeButton} onClick={() => props.reset && props.reset()} />
    </div>
  )
}

function Discovery(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className={styles.FMAASRecentlyPlayed} onClick={() => props.next && props.next(props.nextPage)} />
      <BlipButton className={styles.HomeButton} onClick={() => props.home && props.home()} />
    </div>
  )
}

function MediaPlayer(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className={styles.FMAASMediaPlayerBackButton} onClick={() => props.prev && props.prev()} />
      <BlipButton className={styles.FMAASMediaPlayerSourcesButton} onClick={() => props.home && props.home()} />
    </div>
  )
}

const FMAASConfig = {
  "Title": "Ford Music as an Audio Source",
  "AppTrayContentPages": [
    {
      "title": "",
      "bg": tray,
      "component": AppsTray,
      "next": 0
    }
  ],
  "DiscoveryContentPages": [
    {
      "title": "Ford Music",
      "bg": discover,
      "component": Discovery,
      "next": 0
    }
  ],
  "MusicPlayerContentPages": [
    {
      "bg": player,
      "component": MediaPlayer,
      "musicPlayer": {
        "bg": "bg3.png",
        "playbackComponent": "<FordMusicPlayerPlayback />",
        "topBarComponent": "<FordMusicPlayerTopBar />"
      }
    }
  ]
}

export default FMAASConfig;