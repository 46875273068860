// AnimationContext.js
import React, { createContext, useContext, useState, useCallback } from 'react';

const AnimationContext = createContext();

export const AnimationProvider = ({ children }) => {
  const [animate, setAnimate] = useState(false);

  const triggerAnimation = useCallback(() => {
    console.log('triggeranimation')
    setAnimate(true);
    setTimeout(() => setAnimate(false), 333); // Reset animation state after 1 second
  }, []);

  return (
    <AnimationContext.Provider value={{ animate, triggerAnimation }}>
      {children}
    </AnimationContext.Provider>
  );
};

export const useAnimation = () => {
  return useContext(AnimationContext);
};
