import tray from './img/Ref.png';
import discover from './img/FordMusic_Discover.png';
import player from './img/FordMusic_MusicPlayerScreen.png';
import BlipButton from '../../../blip/BlipButton';

import './FMAAS.css'

function Default(props) {
  return (
    <div>
      <img className="Chrome" src={props.bg}/>
      <BlipButton className="HomeButton" onClick={() => props.reset && props.reset()} />
    </div>
  );
}

function AppsTray(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className="FMAASRadioIcon" onClick={() => props.next && props.next(props.nextPage)}>
      </BlipButton>
    </div>
  )
}

function Discovery(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className="FMAASRecentlyPlayed" onClick={() => props.next && props.next(props.nextPage)} />
      <BlipButton className="HomeButton" onClick={() => props.home && props.home()} />
    </div>
  )
}

function MediaPlayer(props) {
  return (
    <div>
      <Default {...props} />
      <BlipButton className="FMAASMediaPlayerBackButton" onClick={() => props.prev && props.prev()} />
      <BlipButton className="HomeButton" onClick={() => props.home && props.home()} />
    </div>
  )
}

const FMAASConfig = {
  "Title": "Ford Music as an Audio Source",
  "AppTrayContentPages": [
    {
      "title": "",
      "bg": tray,
      "component": AppsTray,
      "next": 0
    }
  ],
  "DiscoveryContentPages": [
    {
      "title": "Ford Music",
      "bg": discover,
      "component": Discovery,
      "next": 0
    }
  ],
  "MusicPlayerContentPages": [
    {
      "bg": player,
      "component": MediaPlayer,
      "musicPlayer": {
        "bg": "bg3.png",
        "playbackComponent": "<FordMusicPlayerPlayback />",
        "topBarComponent": "<FordMusicPlayerTopBar />"
      }
    }
  ]
}

export default FMAASConfig;